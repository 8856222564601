<template>
  <div>
    <fieldset v-bind:disabled="isFormDisabled">
      <div>
        <div class="form-group col-md-12 mt-4">
          <floating-field type="text" id="vesselName" label="name" v-model="vessel.name" :isRequired="true" invalidFeedback="please input vessel name"></floating-field>
        </div>
        <div class="form-group mt-4">
          <select class="form-control col-md-4" v-model="vessel.vesselTypeId" required>
            <option value="" disabled>-- Select a type --</option>
            <option v-for="vesselType in selectableVesselTypes" :value="vesselType.id">{{ vesselType.name }}</option>
          </select>
          <div class="col-md-4 invalid-feedback">
            Please select a vessel type.
          </div>
        </div>
        <div class="col-md-3 mt-4">
          <floating-field type="number" id="grt" label="grt" v-model="vessel.grt" :isRequired="true" invalidFeedback="please input GRT" :step="0.1"></floating-field>
          <div class="col-md-4 invalid-feedback">
            Please input a valid GRT
          </div>
        </div>
        <div class="form-group row mt-4">
          <input type="checkbox" class="form-control col-md-1 my-auto" style="margin-left:-15px" v-model="vessel.status" />
          <label class="col-md-4 mr-0 mt-2">{{ vessel.status | toStatusDescription }}</label>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="scss">
  fieldset {
    border: 0;
  }
</style>

<script>
  import { mapState, mapGetters } from 'vuex'

  import Enums from '@/utils/enums'

  import { toStatusDescription } from '@/filters/enum'
  import FloatingField from '@/components/ui/FloatingField'

  export default {
    name: 'vessel-data-entry',
    components: {
      FloatingField
    },
    props: {
      vessel: {
        type: Object,
        required: true,
      },
      isFormDisabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      ...mapState({
        vesselTypes: state => state.vesselTypeModule.vesselTypes
      }),
      ...mapGetters([
        'activeVesselTypes',
      ]),
      selectableVesselTypes() {
        if (this.vessel.id) {
          return this.vesselTypes.filter((v) => v.status = Enums.Status.Active.value || v.id == this.vessel.vesselTypeId)
        }
        return this.activeVesselTypes
      }
    },
    filters: {
      toStatusDescription
    }
  }
</script>

<template>
  <div id="userGroupAdminView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>manage user groups</h4>
        <div class="d-flex justify-content-end clearfix mb-4">
          <button type="button" class="btn btn-primary " @click="addUserGroup"><i class="icon-expand-plus" title="add new userGroup"></i></button>
        </div>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-modal :show="showAddEditModal" :title="userGroup.id ? 'edit user group' : 'add new user group'">
          <ul class="error">
            <li v-for="error in errors">
              <span class="font-weight-bold">{{ error.title }}</span>: {{ error.message }}
            </li>
          </ul>

          <form id="userGroupEntryForm">
            <UserGroupDataEntry :userGroup="userGroup" :isFormDisabled="isSavingUserGroup"></UserGroupDataEntry>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelAddOrEdit" v-bind:disabled="isSavingUserGroup">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingUserGroup }" @click.prevent="saveUserGroup" v-bind:disabled="isSavingUserGroup">Submit<span class="spinner"></span></button>
          </template>
        </app-modal>
        <app-modal :show="showChangeStatusModal" title="remove userGroup">
          <ul class="error">
            <li v-for="error in errors">
              <span class="font-weight-bold">{{ error.title }}</span>: {{ error.message }}
            </li>
          </ul>
          <form>
            <fieldset v-bind:disabled="isChangingUserGroupStatus">
              <span class="font-weight-bold">Are you sure you want to {{ userGroup.status === Enums.Status.Active.value ? 'disable' : 'enable' }}&nbsp;{{ userGroup.name }} ?</span>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelDisable" v-bind:disabled="isChangingUserGroupStatus">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isChangingUserGroupStatus }" @click.prevent="changeUserGroupStatus" v-bind:disabled="isChangingUserGroupStatus">{{ userGroup.status === Enums.Status.Active.value ? 'Disable' : 'Enable' }}<span class="spinner"></span></button>
          </template>
        </app-modal>
        <UserGroupList ref="userGroupList" :tableProps="userGroupListProps" :id="'userGroupList'+ currentTableKeyIndex" :key="'userGroupList-'+ currentTableKeyIndex" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  input:invalid {
    border: 1px solid red !important;
  }
</style>

<script>
  import store from '@/store'
  import { mapState } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import Enums from '@/utils/enums'

  import { FETCH_USERGROUPS, CREATE_USERGROUP, UPDATE_USERGROUP, UPDATE_USERGROUP_STATUS } from '@/store/action-type'
  import { CLOSE_MODAL_EVENT, EDIT_USERGROUP_EVENT, CHANGE_USERGROUP_STATUS_EVENT } from '@/utils/constants'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import UserGroupDataEntry from '@/components/admin/userGroup/EntryForm'
  import UserGroupList from '@/components/admin/userGroup/UserGroupList'

  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'UserGroupView',
    components: {
      AppAlert,
      AppModal,
      UserGroupDataEntry,
      UserGroupList
    },
    mixins: [AlertMixin, ErrorMixin],
    data: () => ({
      userGroup: {
        id: null,
        name: '',
        cbm: '',
        tonnage: '',
        status: Enums.Status.Active.value,
        version: null
      },
      userGroupListProps: {
        order: null,
        page: null,
        search: null,
        layout: 'list'
      },
      currentTableKeyIndex: 1,
      showAddEditModal: false,
      showChangeStatusModal: false,
      isSavingUserGroup: false,
      isChangingUserGroupStatus: false,
      Enums
    }),
    computed: {
      ...mapState([
        'UserGroups'
      ])
    },
    watch: {
      showAddEditModal(value) {
        if (value) {
          this.$nextTick(() => {
            document.getElementById('userGroupName').focus()
          })
        }
      }
    },
    methods: {
      addUserGroup() {
        this.userGroup.id = null
        this.userGroup.name = ''
        this.userGroup.cbm = ''
        this.userGroup.tonnage = ''
        this.userGroup.status = Enums.Status.Active.value
        this.showAddEditModal = true
      },
      editUserGroup(userGroup) {
        this.userGroup.id = userGroup.id
        this.userGroup.name = userGroup.name
        this.userGroup.cbm = userGroup.cbm
        this.userGroup.tonnage = userGroup.tonnage
        this.userGroup.status = userGroup.status
        this.userGroup.version = userGroup.version
        this.showAddEditModal = true
      },
      onChangeUserGroupStatus(userGroup) {
        this.userGroup.id = userGroup.id
        this.userGroup.name = userGroup.name
        this.userGroup.status = userGroup.status
        this.showChangeStatusModal = true
      },
      saveUserGroup() {
        if (this.validateAddForm()) {
          const self = this
          self.isSavingUserGroup = true
          self.closeMessage()
          let userGroup = { ...self.userGroup }
          const actionType = userGroup.id ? UPDATE_USERGROUP : CREATE_USERGROUP
          store.dispatch(actionType, userGroup).then(() => {
            self.showSuccessMessage(`userGroup created successfully`)
            self.currentTableKeyIndex = self.currentTableKeyIndex + 1
            self.$nextTick(() => {
              self.$refs.userGroupList.refresh();
            });
            self.showAddEditModal = false
          }).catch((error) => {
            self.errors.push({
              title: 'error',
              message: error
            })
          }).finally(() => {
            self.isSavingUserGroup = false
          })
        }
      },
      changeUserGroupStatus() {
        const self = this
        self.isChangingUserGroupStatus = true
        self.closeMessage()
        store.dispatch(UPDATE_USERGROUP_STATUS, self.userGroup).then(() => {
          self.showSuccessMessage(`status changed successfully`)
          self.currentTableKeyIndex = self.currentTableKeyIndex + 1
          self.$nextTick(() => {
            self.$refs.userGroupList.refresh();
          });
          self.showChangeStatusModal = false
        }).catch((error) => {
          //self.handleError(error)
          self.errors.push({
            title: 'error',
            message: error
          })
        }).finally(() => {
          self.isChangingUserGroupStatus = false
        })
      },
      cancelAddOrEdit() {
        this.errors.splice(0, this.errors.length)
        this.showAddEditModal = false
      },
      cancelDisable() {
        this.showChangeStatusModal = false
        this.errors.splice(0, this.errors.length)
      },
      validateAddForm() {
        const self = this
        self.errors.splice(0, self.errors.length)
        let form = $('#userGroupEntryForm')

        var invalidFields = form.find(":invalid").not('fieldset').each(function (index, node) {
          // Find the field's corresponding label
          var label = $("label[for=" + node.id + "] "),
            // Opera incorrectly does not fill the validationMessage property.
            message = node.validationMessage || 'Invalid value.';
          self.errors.push({
            title: label.text(),
            message: message
          })
        });
        return invalidFields.length == 0
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, 'loading data')
      const tasks = []
      tasks.push(store.dispatch(FETCH_USERGROUPS))
      Promise.all(tasks).then(() => {
        next()
      }).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
        })
      }).finally(() => {
        eventBus.$emit(Events.LongOperationCompleted)
      })
    },
    mounted() {
      const self = this
      eventBus.$on(EDIT_USERGROUP_EVENT, self.editUserGroup)
      eventBus.$on(CHANGE_USERGROUP_STATUS_EVENT, self.onChangeUserGroupStatus)
      eventBus.$on(CLOSE_MODAL_EVENT, () => {
        this.errors.splice(0, this.errors.length)
        this.showAddEditModal = false
        this.showChangeStatusModal = false
      })
      eventBus.$on(CLOSE_MODAL_EVENT, () => {
        self.showAddEditModal = false
      })
    },
    beforeDestroy() {
      eventBus.$off(EDIT_USERGROUP_EVENT)
      eventBus.$off(CHANGE_USERGROUP_STATUS_EVENT)
      eventBus.$off(CLOSE_MODAL_EVENT)
    }
  }
</script>

<template>
  <div>
    <fieldset v-bind:disabled="isFormDisabled">
      <div>
        <div class="form-group col-md-12 mt-4">
          <floating-field type="text" id="vesselTypeName" label="name" v-model="vesselType.name" :isRequired="true" invalidFeedback="please input vessel type name"></floating-field>
        </div>
        <div class="form-group row mt-4">
          <input type="checkbox" class="form-control col-md-1 my-auto" style="margin-left:-15px" v-model="vesselType.status" />
          <label class="col-md-4 mr-0 mt-2">{{ vesselType.status | toStatusDescription }}</label>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="scss">
  fieldset {
    border: 0;
  }
</style>

<script>

  import { toStatusDescription } from '@/filters/enum'
  import FloatingField from '@/components/ui/FloatingField'

  export default {
    name: 'vesselType-data-entry',
    components: {
      FloatingField
    },
    props: {
      vesselType: {
        type: Object,
        required: true,
      },
      isFormDisabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    filters: {
      toStatusDescription
    }
  }
</script>

<template>
  <div id="LocationAdminView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>manage locations</h4>
        <div class="d-flex justify-content-end clearfix mb-4">
          <button type="button" class="btn btn-primary " @click="addLocation"><i class="icon-expand-plus" title="add new location"></i></button>
        </div>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-modal :show="showAddEditModal" :title="location.id ? 'edit location' : 'add new location'">
          <ul class="error">
            <li v-for="error in errors">
              <span class="font-weight-bold">{{ error.title }}</span>: {{ error.message }}
            </li>
          </ul>

          <form id="locationEntryForm">
            <LocationDataEntry :location="location" :isFormDisabled="isSavingLocation"></LocationDataEntry>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelAddOrEdit" v-bind:disabled="isSavingLocation">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingLocation }" @click.prevent="saveLocation" v-bind:disabled="isSavingLocation">Submit<span class="spinner"></span></button>
          </template>
        </app-modal>
        <app-modal :show="showChangeStatusModal" title="remove location">
          <ul class="error">
            <li v-for="error in errors">
              <span class="font-weight-bold">{{ error.title }}</span>: {{ error.message }}
            </li>
          </ul>
          <form>
            <fieldset v-bind:disabled="isChangingLocationStatus">
              <span class="font-weight-bold">Are you sure you want to {{ location.status === Enums.Status.Active.value ? 'disable' : 'enable' }}&nbsp;{{ location.name }} ?</span>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelDisable" v-bind:disabled="isChangingLocationStatus">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isChangingLocationStatus }" @click.prevent="changeLocationStatus" v-bind:disabled="isChangingLocationStatus">{{ location.status === Enums.Status.Active.value ? 'Disable' : 'Enable' }}<span class="spinner"></span></button>
          </template>
        </app-modal>
        <LocationList ref="locationList" :tableProps="locationListProps" :id="'locationList'+ currentTableKeyIndex" :key="'locationList-'+ currentTableKeyIndex" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  input:invalid {
    border: 1px solid red !important;
  }
</style>

<script>
  import store from '@/store'
  import { mapState } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import Enums from '@/utils/enums'

  import { FETCH_LOCATIONS, CREATE_LOCATION, UPDATE_LOCATION, UPDATE_LOCATION_STATUS } from '@/store/action-type'
  import { CLOSE_MODAL_EVENT, EDIT_LOCATION_EVENT, CHANGE_LOCATION_STATUS_EVENT } from '@/utils/constants'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import LocationDataEntry from '@/components/admin/location/EntryForm'
  import LocationList from '@/components/admin/location/LocationList'

  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'LocationView',
    components: {
      AppAlert,
      AppModal,
      LocationDataEntry,
      LocationList
    },
    mixins: [AlertMixin, ErrorMixin],
    data: () => ({
      location: {
        id: null,
        name: '',
        isTerminal: false,
        status: Enums.Status.Active.value,
        version: null
      },
      locationListProps: {
        order: null,
        page: null,
        search: null,
        layout: 'list'
      },
      currentTableKeyIndex: 1,
      showAddEditModal: false,
      showChangeStatusModal: false,
      isSavingLocation: false,
      isChangingLocationStatus: false,
      Enums
    }),
    computed: {
      ...mapState([
        'Locations'
      ])
    },
    watch: {
      showAddEditModal(value) {
        if (value) {
          this.$nextTick(() => {
            document.getElementById('locationName').focus()
          })
        }
      }
    },
    methods: {
      addLocation() {
        this.location.id = null
        this.location.name = ''
        this.location.status = Enums.Status.Active.value
        this.location.isTerminal = false
        this.showAddEditModal = true
      },
      editLocation(location) {
        this.location.id = location.id
        this.location.name = location.name
        this.location.status = location.status
        this.location.isTerminal = location.isTerminal
        this.location.version = location.version
        this.showAddEditModal = true
      },
      onChangeLocationStatus(location) {
        this.location.id = location.id
        this.location.name = location.name
        this.location.status = location.status
        this.showChangeStatusModal = true
      },
      saveLocation() {
        if (this.validateAddForm()) {
          const self = this
          self.isSavingLocation = true
          self.closeMessage()
          let location = {...self.location}
          const actionType = location.id ? UPDATE_LOCATION : CREATE_LOCATION
          store.dispatch(actionType, location).then(() => {
            self.showSuccessMessage(` location created successfully`)
            self.currentTableKeyIndex = self.currentTableKeyIndex + 1
            self.$nextTick(() => {
              self.$refs.locationList.refresh();
            });
            self.showAddEditModal = false
          }).catch((error) => {
            self.errors.push({
              title: 'error',
              message: error
            })
          }).finally(() => {
            self.isSavingLocation = false
          })
        }
      },
      changeLocationStatus() {
        const self = this
        self.isChangingLocationStatus = true
        self.closeMessage()
        store.dispatch(UPDATE_LOCATION_STATUS, self.location).then(() => {
          self.showSuccessMessage(`status changed successfully`)
          self.currentTableKeyIndex = self.currentTableKeyIndex + 1
          self.$nextTick(() => {
            self.$refs.locationList.refresh();
          });
          self.showChangeStatusModal = false
        }).catch((error) => {
          //self.handleError(error)
          self.errors.push({
            title: 'error',
            message: error
          })
        }).finally(() => {
          self.isChangingLocationStatus = false
        })
      },
      cancelAddOrEdit() {
        this.errors.splice(0, this.errors.length)
        this.showAddEditModal = false
      },
      cancelDisable() {
        this.showChangeStatusModal = false
        this.errors.splice(0, this.errors.length)
      },
      validateAddForm() {
        const self = this
        self.errors.splice(0, self.errors.length)
        let form = $('#locationEntry')

        var invalidFields = form.find(":invalid").not('fieldset').each(function (index, node) {
          // Find the field's corresponding label
          var label = $("label[for=" + node.id + "] "),
            // Opera incorrectly does not fill the validationMessage property.
            message = node.validationMessage || 'Invalid value.';
          self.errors.push({
            title: label.text(),
            message: message
          })
        });
        return invalidFields.length == 0
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, 'loading data')
      const tasks = []
      tasks.push(store.dispatch(FETCH_LOCATIONS))
      Promise.all(tasks).then(() => {
        next()
      }).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
        })
      }).finally(() => {
        eventBus.$emit(Events.LongOperationCompleted)
      })
    },
    mounted() {
      const self = this
      eventBus.$on(EDIT_LOCATION_EVENT, self.editLocation)
      eventBus.$on(CHANGE_LOCATION_STATUS_EVENT, self.onChangeLocationStatus)
      eventBus.$on(CLOSE_MODAL_EVENT, () => {
        this.errors.splice(0, this.errors.length)
        this.showAddEditModal = false
        this.showChangeStatusModal = false
      })
      eventBus.$on(CLOSE_MODAL_EVENT, () => {
        self.showAddEditModal = false
      })
    },
    beforeDestroy() {
      eventBus.$off(EDIT_LOCATION_EVENT)
      eventBus.$off(CHANGE_LOCATION_STATUS_EVENT)
      eventBus.$off(CLOSE_MODAL_EVENT)
    }
  }
</script>

<template>
  <div>
    <fieldset v-bind:disabled="isFormDisabled">
      <div>
        <div class="form-group col-md-12 mt-4">
          <floating-field type="text" id="bargeName" label="name" v-model="barge.name" :isRequired="true" invalidFeedback="please input barge name"></floating-field>
        </div>
        <div class="col-md-3 mt-4">
          <floating-field type="number" id="tonnage" label="tonnage" @blur="calculateCBM" v-model="barge.tonnage" :isRequired="true" invalidFeedback="please input tonnage" :step="'0.1'"></floating-field>
          <div class="col-md-4 invalid-feedback">
            Please input a valid tonnage
          </div>
        </div>

        <div class="col-md-3 mt-4">
          <floating-field type="number" id="CBM" label="CBM" v-model="barge.cbm" :isRequired="true" invalidFeedback="please input cbm" :step="'0.1'"></floating-field>
          <div class="col-md-4 invalid-feedback">
            Please input a valid CBM
          </div>
        </div>
        <div class="form-group row mt-4">
          <input type="checkbox" class="form-control col-md-1 my-auto" style="margin-left:-15px" v-model="barge.status" />
          <label class="col-md-4 mr-0 mt-2">{{ barge.status | toStatusDescription }}</label>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="scss">
  fieldset {
    border: 0;
  }
</style>

<script>


  import { mapState, mapGetters } from 'vuex'
  import { toStatusDescription } from '@/filters/enum'
  import FloatingField from '@/components/ui/FloatingField'

  export default {

    name: 'barge-data-entry',
    components: {
      FloatingField
    },
    props: {
      barge: {
        type: Object,
        required: true,
      },
      isFormDisabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    computed: {
      ...mapState([
        'vct'
      ]),
      ...mapState({
        vct: state => state.vctModule.vct,
      }),
      ...mapGetters([,
        'activeVCT'
      ]),
      bargeValue: {
        get() {
          return this.barge.tonnage;
        },
        set(value) {
          this.barge.tonnage = value;
        },
      },
    },
    watch: {
      bargeValue: {
        handler: 'calculateCBM',
        immediate: false, // Set to true if you want to calculate on component mount as well
      },
    },
    methods: {
      calculateCBM() {
        const bargeFieldValue = parseFloat(this.bargeValue);
        const activeVCT = this.activeVCT;

        if (isNaN(bargeFieldValue) || !activeVCT.length) {
          this.cbm = ''; // Clear the cbm field if barge value is not a number or activeVCT is empty
          return;
        }

        // Sort the activeVCT list by tonnage in ascending order
        const sortedVCT = activeVCT.sort((a, b) => a.calculatedGrossTonnage - b.calculatedGrossTonnage);

        let lowerTonnage = null;
        let higherTonnage = null;
        let lowerCoefficient = null;
        let higherCoefficient = null;

        for (let i = 0; i < sortedVCT.length; i++) {
          if (sortedVCT[i].calculatedGrossTonnage <= bargeFieldValue) {
            lowerTonnage = sortedVCT[i].calculatedGrossTonnage;
            lowerCoefficient = sortedVCT[i].coefficient;
          } else {
            higherTonnage = sortedVCT[i].calculatedGrossTonnage;
            higherCoefficient = sortedVCT[i].coefficient;
            break;
          }
        }

        if (lowerTonnage === null || higherTonnage === null) {
          this.cbm = ''; // Clear the cbm field if suitable tonnage values not found
          return;
        }

        const coefficient = (bargeFieldValue - lowerTonnage) * (higherCoefficient - lowerCoefficient) /
          (higherTonnage - lowerTonnage) + lowerCoefficient;

        const cbm = bargeFieldValue / coefficient;
        this.barge.cbm = cbm.toFixed(2);
      },
    },

    filters: {
      toStatusDescription
    }
  }
</script>

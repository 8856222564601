<template>
  <div id="bargeAdminView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>manage barges</h4>
        <div class="d-flex justify-content-end clearfix mb-4">
          <button type="button" class="btn btn-primary " @click="addBarge"><i class="icon-expand-plus" title="add new barge"></i></button>
        </div>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-modal :show="showAddEditModal" :title="barge.id ? 'edit barge' : 'add new barge'">
          <ul class="error">
            <li v-for="error in errors">
              <span class="font-weight-bold">{{ error.title }}</span>: {{ error.message }}
            </li>
          </ul>

          <form id="bargeEntryForm">
            <BargeDataEntry :barge="barge" :isFormDisabled="isSavingBarge"></BargeDataEntry>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelAddOrEdit" v-bind:disabled="isSavingBarge">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingBarge }" @click.prevent="saveBarge" v-bind:disabled="isSavingBarge">Submit<span class="spinner"></span></button>
          </template>
        </app-modal>
        <app-modal :show="showChangeStatusModal" title="remove barge">
          <ul class="error">
            <li v-for="error in errors">
              <span class="font-weight-bold">{{ error.title }}</span>: {{ error.message }}
            </li>
          </ul>
          <form>
            <fieldset v-bind:disabled="isChangingBargeStatus">
              <span class="font-weight-bold">Are you sure you want to {{ barge.status === Enums.Status.Active.value ? 'disable' : 'enable' }}&nbsp;{{ barge.name }} ?</span>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelDisable" v-bind:disabled="isChangingBargeStatus">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isChangingBargeStatus }" @click.prevent="changeBargeStatus" v-bind:disabled="isChangingBargeStatus">{{ barge.status === Enums.Status.Active.value ? 'Disable' : 'Enable' }}<span class="spinner"></span></button>
          </template>
        </app-modal>
        <BargeList ref="bargeList" :tableProps="bargeListProps" :id="'bargeList'+ currentTableKeyIndex" :key="'bargeList-'+ currentTableKeyIndex" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  input:invalid {
    border: 1px solid red !important;
  }
</style>

<script>
  import store from '@/store'
  import { mapState, mapGetters } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import Enums from '@/utils/enums'

  import { FETCH_BARGES, FETCH_VCT, CREATE_BARGE, UPDATE_BARGE, UPDATE_BARGE_STATUS } from '@/store/action-type'
  import { CLOSE_MODAL_EVENT, EDIT_BARGE_EVENT, CHANGE_BARGE_STATUS_EVENT } from '@/utils/constants'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import BargeDataEntry from '@/components/admin/barge/EntryForm'
  import BargeList from '@/components/admin/barge/BargeList'

  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'BargeView',
    components: {
      AppAlert,
      AppModal,
      BargeDataEntry,
      BargeList
    },
    mixins: [AlertMixin, ErrorMixin],
    data: () => ({
      barge: {
        id: null,
        name: '',
        cbm: '',
        tonnage: '',
        status: Enums.Status.Active.value,
        version: null
      },
      bargeListProps: {
        order: null,
        page: null,
        search: null,
        layout: 'list'
      },
      currentTableKeyIndex: 1,
      showAddEditModal: false,
      showChangeStatusModal: false,
      isSavingBarge: false,
      isChangingBargeStatus: false,
      Enums
    }),

    computed: {
      ...mapState([
        'Barges',
        'Vct'
      ]),
      ...mapState({
        vct: state => state.vctModule.vct,
      }),
      ...mapGetters([,
        'Vct'
      ]),
    },

    watch: {
      showAddEditModal(value) {
        if (value) {
          this.$nextTick(() => {
            document.getElementById('bargeName').focus()
          })
        }
      }
    },
    methods: {
      addBarge() {
        this.barge.id = null
        this.barge.name = ''
        this.barge.cbm = ''
        this.barge.tonnage = ''
        this.barge.status = Enums.Status.Active.value
        this.showAddEditModal = true
      },
      editBarge(barge) {
        this.barge.id = barge.id
        this.barge.name = barge.name
        this.barge.cbm = barge.cbm.toString()
        this.barge.tonnage = barge.tonnage.toString()
        this.barge.status = barge.status
        this.barge.version = barge.version
        this.showAddEditModal = true
      },
      onChangeBargeStatus(barge) {
        this.barge.id = barge.id
        this.barge.name = barge.name
        this.barge.status = barge.status
        this.showChangeStatusModal = true
      },
      saveBarge() {
        if (this.validateAddForm()) {
          const self = this
          self.isSavingBarge = true
          self.closeMessage()
          let barge = { ...self.barge }
          const actionType = barge.id ? UPDATE_BARGE : CREATE_BARGE
          store.dispatch(actionType, barge).then(() => {
            self.showSuccessMessage(`barge created successfully`)
            self.currentTableKeyIndex = self.currentTableKeyIndex + 1
            self.$nextTick(() => {
              self.$refs.bargeList.refresh();
            });
            self.showAddEditModal = false
          }).catch((error) => {
            self.errors.push({
              title: 'error',
              message: error
            })
          }).finally(() => {
            self.isSavingBarge = false
          })
        }
      },
      changeBargeStatus() {
        const self = this
        self.isChangingBargeStatus = true
        self.closeMessage()
        store.dispatch(UPDATE_BARGE_STATUS, self.barge).then(() => {
          self.showSuccessMessage(`status changed successfully`)
          self.currentTableKeyIndex = self.currentTableKeyIndex + 1
          self.$nextTick(() => {
            self.$refs.bargeList.refresh();
          });
          self.showChangeStatusModal = false
        }).catch((error) => {
          //self.handleError(error)
          self.errors.push({
            title: 'error',
            message: error
          })
        }).finally(() => {
          self.isChangingBargeStatus = false
        })
      },
      cancelAddOrEdit() {
        this.errors.splice(0, this.errors.length)
        this.showAddEditModal = false
      },
      cancelDisable() {
        this.showChangeStatusModal = false
        this.errors.splice(0, this.errors.length)
      },
      validateAddForm() {
        const self = this
        self.errors.splice(0, self.errors.length)
        let form = $('#bargeEntryForm')

        var invalidFields = form.find(":invalid").not('fieldset').each(function (index, node) {
          // Find the field's corresponding label
          var label = $("label[for=" + node.id + "] "),
            // Opera incorrectly does not fill the validationMessage property.
            message = node.validationMessage || 'Invalid value.';
          self.errors.push({
            title: label.text(),
            message: message
          })
        });
        return invalidFields.length == 0
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, 'loading data')
      const tasks = []
      tasks.push(store.dispatch(FETCH_BARGES))
      tasks.push(store.dispatch(FETCH_VCT))
      Promise.all(tasks).then(() => {
        next()
      }).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
        })
      }).finally(() => {
        eventBus.$emit(Events.LongOperationCompleted)
      })
    },
    mounted() {
      const self = this
      eventBus.$on(EDIT_BARGE_EVENT, self.editBarge)
      eventBus.$on(CHANGE_BARGE_STATUS_EVENT, self.onChangeBargeStatus)
      eventBus.$on(CLOSE_MODAL_EVENT, () => {
        this.errors.splice(0, this.errors.length)
        this.showAddEditModal = false
        this.showChangeStatusModal = false
      })
      eventBus.$on(CLOSE_MODAL_EVENT, () => {
        self.showAddEditModal = false
      })
    },
    beforeDestroy() {
      eventBus.$off(EDIT_BARGE_EVENT)
      eventBus.$off(CHANGE_BARGE_STATUS_EVENT)
      eventBus.$off(CLOSE_MODAL_EVENT)
    }
  }


</script>

<template>
  <div>
    <table :id="id" class="display table table-condensed" style="width:100% !important; border-collapse:collapse;">
      <thead>
        <tr>
          <th width="2%"></th>
          <th>vessel type</th>
          <th>status</th>
          <th>actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(vesselType, index) in vesselTypes" :key="vesselType.id" :id="vesselType.id">
          <td>{{ index + 1 }}</td>
          <td>{{ vesselType.name }}</td>
          <td>{{ vesselType.status | toStatusDescription }}</td>
          <td>
            <span class="action-btn mr-2 font-weight-bold h5" title="edit vesselType" @click="editVesselType(vesselType)">&#x270E;</span>
            <span class="action-btn font-weight-bold h5" :title="vesselType.status === Enums.Status.Active.value ? 'disable vessel type' : 'enable vessel type'" @click="changeVesselTypeStatus(vesselType)">{{ vesselType.status === Enums.Status.Active.value ? '&#x2716;' : '&#10004' }}</span>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<style scoped lang="scss">
  .action-btn {
    cursor: pointer
  }
</style>

<script>
  import { mapState } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import { EDIT_VESSELTYPE_EVENT, CHANGE_VESSELTYPE_STATUS_EVENT } from '@/utils/constants'

  import Enums from '@/utils/enums'
  import { toStatusDescription } from '@/filters/enum'

  //import DataTable from '@/components/ui/DataTable'
  //import AppPager from '@/components/ui/AppPager'

  export default {
    name: 'vesselType-list',
    components: {
      //DataTable,
      //AppPager
    },
    props: {
      id: {
        type: String,
        required: false,
        default: 'vesselTypeList'
      },
      tableProps: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      tableObject: null,
      initialized: false,
      Enums: Enums
    }),
    computed: {
      ...mapState({
        vesselTypes: state => state.vesselTypeModule.vesselTypes
      })
    },
    filters: {
      toStatusDescription
    },
    methods: {
      refresh() {
        const self = this;
        if (self.tableObject) {
          self.tableObject.destroy();
        }

        self.tableObject = $(`#${self.id}`).DataTable({
          searching: true,
          paging: true,
          info: false,
          //order: (self.tableProps && self.tableProps.order ? self.tableProps.order : []),
          //pageLength: (self.tableProps && self.tableProps.page ? self.tableProps.page.length : 10),
          //displayStart: (self.tableProps && self.tableProps.page ? self.tableProps.page.length * self.tableProps.page.page : 0),
          //search: (self.tableProps && self.tableProps.search ? self.tableProps.search : null),
          dom: 'lfrtip'
        });


        if (self.tableProps.order && self.tableProps.order.length) {
          //alert(self.tableProps.order )
          self.tableObject.order(self.tableProps.order)
        }
        //$(`#${self.id}`).on('page.dt', function () {
        //  self.tableProps.page = self.tableObject.page.info();
        //});

        $(`#${self.id}`).on('order.dt', function (e, settings) {
          if (self.tableObject.order()) {
            self.tableProps.order = self.tableObject.order()
          }
        });

        //$(`#${self.id}`).on('search.dt', function () {
        //  self.tableProps.search = self.tableObject.search();
        //});
      },
      editVesselType(vesselType) {
        eventBus.$emit(EDIT_VESSELTYPE_EVENT, vesselType)
      },
      changeVesselTypeStatus(vesselType) {
        eventBus.$emit(CHANGE_VESSELTYPE_STATUS_EVENT, vesselType)
      }
    },
    mounted() {
      this.refresh()
    },
    beforeDestroy() {
      if (this.tableObject) {
        this.$nextTick(() => {
          this.tableObject.destroy();
        });
      }
    }
  }
</script>

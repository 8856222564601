<template>
  <table>
    <thead>
      <tr>
        <th v-if="showSerialNumber">s/n</th>
        <th v-for="(header, index) in headers" :key="header.name">{{ header.title || header.name }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="'item_row_' + item[identifierProp]">
        <td v-if="showSerialNumber">{{ index + 1 }}</td>
        <td v-for="header in headers" :key="'item_column_' + header.name + '_' + item[identifierProp]">
          <slot :name="'item.' + header.name" v-bind:item="item">
            <span>{{ item[header.name] }}</span>
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    name: 'data-table',
    props: {
      id: {
        type: String,
        required: false,
        default: 'my-data-table'
      },
      identifierProp: {
        type: String,
        required: false,
        default: 'id'
      },
      headers: {
        type: Array,
        required: false
      },
      items: {
        type: Array,
        required: false
      },
      showSerialNumber: {
        type: Boolean,
        required: false,
        default: true
      }
    }
  }
</script>

import { render, staticRenderFns } from "./UserGroupList.vue?vue&type=template&id=af6d7710&scoped=true"
import script from "./UserGroupList.vue?vue&type=script&lang=js"
export * from "./UserGroupList.vue?vue&type=script&lang=js"
import style0 from "./UserGroupList.vue?vue&type=style&index=0&id=af6d7710&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af6d7710",
  null
  
)

export default component.exports
<template>
  <div>
    <table :id="id" class="display table table-condensed" style="width:100% !important; border-collapse:collapse;">
      <thead>
        <tr>
          <th width="2%"></th>
          <th>role name</th>
          <th>rights</th>
          <th>status</th>
          <th>actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(role, index) in roles" :key="role.id" :id="role.id">
          <td>{{ index + 1 }}</td>
          <td>{{ role.name }}</td>
          <td>{{ role.status | toStatusDescription }}</td>
          <td>{{ role.rights | toRoleRightsDescription }}</td>
          <td>
            <span class="action-btn mr-2 font-weight-bold h5" title="edit role" @click="editRole(role)">&#x270E;</span>
            <span class="action-btn font-weight-bold h5" title="remove location" @click="removeRole(role)">&#x2716;</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
  .action-btn {
    cursor: pointer
  }
</style>

<script>
  import { mapState } from 'vuex'

  import eventBus from '@/utils/eventBus'

  import { EDIT_ROLE_EVENT, REMOVE_ROLE_EVENT } from '@/utils/constants'
  import { toStatusDescription, toRoleRightsDescription } from '@/filters/enum'

  //import DataTable from '@/components/ui/DataTable'
  //import AppPager from '@/components/ui/AppPager'

  export default {
    name: 'role-list',
    components: {
      //DataTable,
      //AppPager
    },
    props: {
      id: {
        type: String,
        required: false,
        default: 'roleList'
      },
      tableProps: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      tableObject: null,
      initialized: 0
    }),
    computed: {
      ...mapState({
        roles: state => state.roleModule.roles
      })
    },
    filters: {
      toStatusDescription,
      toRoleRightsDescription
    },
    methods: {
      refresh() {
        const self = this;
        if (self.tableObject) {
          self.tableObject.destroy();
        }
        
        self.tableObject = $(`#${self.id}`).DataTable({
          searching: true,
          paging: true,
          info: false,
          //order: (self.tableProps && self.tableProps.order ? self.tableProps.order : []),
          //pageLength: (self.tableProps && self.tableProps.page ? self.tableProps.page.length : 10),
          //displayStart: (self.tableProps && self.tableProps.page ? self.tableProps.page.length * self.tableProps.page.page : 0),
          //search: (self.tableProps && self.tableProps.search ? self.tableProps.search : null),
          dom: 'lfrtip'
        });


        if (self.tableProps.order && self.tableProps.order.length) {
          //alert(self.tableProps.order )
          self.tableObject.order(self.tableProps.order)
        }
        //$(`#${self.id}`).on('page.dt', function () {
        //  self.tableProps.page = self.tableObject.page.info();
        //});

        $(`#${self.id}`).on('order.dt', function (e, settings) {
          if (self.tableObject.order()) {
            eventBus.$emit('ORDER_CHANGED', self.tableObject.order())
            //self.tableProps.order = self.tableObject.order()
          }
        });

        //$(`#${self.id}`).on('search.dt', function () {
        //  self.tableProps.search = self.tableObject.search();
        //});
      },
      editRole(role) {
        eventBus.$emit(EDIT_ROLE_EVENT, role)
      },
      removeRole(role) {
        eventBus.$emit(REMOVE_ROLE_EVENT, role)
      },
      goToPage(page) {
        this.currentPage = page
      }
    },
    mounted() {
      //const self = this
      //$(`#${self.id}`).DataTable({
      //  searching: true,
      //  paging: true,
      //  /*lengthMenu: [9, 12, 15, 18, 20],*/
      //  info: false,
      //  dom: 'lfrtip',
      //  //buttons: [
      //  //  'copy', 'csv', 'excel', 'pdf', 'print'
      //  //]
      //  "language": {
      //    "emptyTable": "No data available yet for display"
      //  }
      //});
      this.refresh()
    },
    beforeDestroy() {
      if (this.tableObject) {
        this.$nextTick(() => {
          this.tableObject.destroy();
        });
      }
    }
  }
</script>

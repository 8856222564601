<template>
  <div id="userView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>manage users</h4>
        <div class="d-flex justify-content-end clearfix mb-4">
          <button type="button" class="btn btn-primary mr-2" @click="addInternalUser"><i class="icon-expand-plus" title="add new chevron user"></i>add chevron user</button>
          <!--<button type="button" class="btn btn-secondary " @click="addExternalUser"><i class="icon-expand-plus" title="add new external user"></i>add external user</button>-->
        </div>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-modal :show="showAddEditInternalUserModal || showAddEditExternalUserModal" :title="userModalTitle">
          <ul class="error">
            <li v-for="error in errors" :key="error.message">
              {{ error.message }}
            </li>
          </ul>
          <form id="userForm" v-if="showAddEditInternalUserModal">
            <fieldset v-bind:disabled="isSavingUser">
              <employee-autocomplete v-if="user.isNew" :items="searchedEmployees" id="emp_search" :sData="user.firstName + ' ' + user.lastName" :isShown="showAddEditInternalUserModal" isRequired />
              <span v-else class="font-weight-bold">{{ user.firstName }}  {{ user.lastName }}</span>

              <div class="form-group row mt-4">
                <label class="col-md-2 mr-0 text-left">Role<span class="text-danger font-weight-bold">*</span></label>
                <div class="col-md-8 mr-0">
                  <select class="form-control" id="userRole" name="userRole" v-model="user.roleName" required>
                    <option value="">-- select role --</option>
                    <option v-for="role in activeRoles" :value="role.name" :key="role.name">{{ role.name }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row mt-4">
                <label class="col-md-2 mr-0 text-left">Location<span class="text-danger font-weight-bold">*</span></label>
                <div class="col-md-8 mr-0">
                  <select class="form-control" id="userLocation" name="userLocation" v-model="user.locationId" required>
                    <option value="">-- select location --</option>
                    <option v-for="location in terminalLocations" :value="location.id" :key="location.id">{{ location.name }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row mt-4">
                <div class="col-md-5 mt-2">
                  <input type="checkbox" class="form-control col-md-2 my-auto" style="margin-left:-30px" v-model="user.status" />
                  <label class="col-md-4 mr-0 mt-2">{{ user.status | toStatusDescription }}</label>
                </div>
                <div class="col-md-5 mt-2">
                  <input type="checkbox" class="form-control col-md-2 my-auto" style="margin-left:-30px" v-model="user.isAdmin" />
                  <label class="col-md-6 mr-0 mt-2">Is System Administrator?</label>
                </div>
              </div>


            </fieldset>
          </form>
          <form id="userForm" v-if="showAddEditExternalUserModal">
            <fieldset v-bind:disabled="isSavingUser">
              <div class="form-group row mt-4">
                <div class="col-md-8 mr-0">
                  <floating-field type="email" id="email" label="email address" v-model="user.email" :showLabel="true" v-bind:isRequired="true"></floating-field>
                </div>
              </div>
              <div class="form-group row mt-4">
                <div class="col-md-8 mr-0">
                  <floating-field type="text" id="firstName" label="first name" v-model="user.firstName" :showLabel="true" v-bind:isRequired="true"></floating-field>
                </div>
              </div>
              <div class="form-group row mt-4">
                <div class="col-md-8 mr-0">
                  <floating-field type="text" id="lastName" label="last name" v-model="user.lastName" :showLabel="true" v-bind:isRequired="true"></floating-field>
                </div>
              </div>
              <div class="form-group row mt-4">
                <input type="checkbox" class="form-control col-md-2 my-auto" style="margin-left:-30px" v-model="user.status" />
                <label class="col-md-4 mr-0 mt-2">{{ user.status | toStatusDescription }}</label>
              </div>
              <div class="form-group row mt-4">
                <div class="col-md-8 mr-0">
                  <select class="form-control" id="userRole" name="userRole" v-model="user.roleId" required>
                    <option value="">-- select role --</option>
                    <option v-for="role in activeRoles" :value="role.id" :key="role.id">{{ role.name }}</option>
                  </select>
                </div>
              </div>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelAddOrEdit" v-bind:disabled="isSavingUser">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingUser }" @click.prevent="saveUser" v-bind:disabled="isSavingUser">Submit<span class="spinner"></span></button>
          </template>
        </app-modal>
        <app-modal :show="showUserStatusChangeModal" title="change user status">
          <form>
            <fieldset v-bind:disabled="isChangingUserStatus">
              Are you sure you want to {{ selectedUserForStatusChange && selectedUserForStatusChange.status ? 'disable' : 'enable' }} this user?
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancelUserStatusChange" v-bind:disabled="isChangingUserStatus">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isChangingUserStatus }" @click.prevent="changeUserStatus" v-bind:disabled="isChangingUserStatus">{{  statusForSelectedUserForStatusChange ? 'Disable user' : 'Enable user' }}<span class="spinner"></span></button>
          </template>
        </app-modal>
        <div class="spinner-border text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <user-list />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

    .spin .spinner {
      left: -.6em;
      top: .4em;
      width: 2.5em;
      display: block;
      position: absolute;
    }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  input:invalid {
    border: 1px solid red !important;
  }
</style>

<script>
  import store from '@/store'
  import { mapGetters, mapState } from 'vuex'
  import { toStatusDescription } from '@/filters/enum'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import { FETCH_USERS, CREATE_USER, UPDATE_USER, CHANGE_USER_STATUS, FETCH_ROLES, FETCH_LOCATIONS } from '@/store/action-type'
  import { CLOSE_MODAL_EVENT, EMPLOYEE_SEARCH_STARTED_EVENT, EMPLOYEE_SELECTED_EVENT, EDIT_USER_EVENT, CHANGE_USER_STATUS_EVENT } from '@/utils/constants'
  import Enums from '@/utils/enums'
  import { employeeService } from '@/services'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import EmployeeAutocomplete from '@/components/ui/EmployeeAutocomplete'
  import UserList from '@/components/user/UserList'
  import FloatingField from '@/components/ui/FloatingField'

  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'UserView',
    components: {
      UserList,
      AppAlert,
      AppModal,
      EmployeeAutocomplete,
      FloatingField
    },
    mixins: [AlertMixin, ErrorMixin],
    data: () => ({
      user: {
        email: '',
        firstName: '',
        lastName: '',
        //fullName: '',
        roleName: '',
        locationId: '',
        status: Enums.Status.Active.value,
        isAdmin: false,
        isNew: true
      },
      location: {},
      selectedUserForStatusChange: null,
      statusForSelectedUserForStatusChange: null,
      showAddEditInternalUserModal: false,
      showAddEditExternalUserModal: false,
      showUserStatusChangeModal: false,
      isSavingUser: false,
      isChangingUserStatus: false,
      searchedEmployees: []
    }),
    computed: {
      ...mapState({
        locations: state => state.locationModule.locations,
      }),
      ...mapGetters([
        'activeRoles',
        'activeEnterpriseLocations',
        'terminalLocations'
      ]),
      userModalTitle() {
        let userType = this.showAddEditInternalUserModal ? 'internal user' : 'external user'
        if (this.user.isNew) {
          return `add ${userType} user`
        }
        else {
          return `edit ${userType} user`
        }
      }
    },
    watch: {
      showAddEditInternalUserModal(value) {
        if (value && this.user.isNew) {
          this.$nextTick(() => {
            document.getElementById('emp_search').focus()
          })
        }
      }
    },
    methods: {
      resetUserData() {
        this.user.email = ''
        this.user.firstName = ''
        this.user.lastName = ''
        //this.user.fullName = ''
        this.user.roleName = ''
        this.user.locationId = ''
        this.user.status = Enums.Status.Active.value
        this.user.isAdmin = false
        this.user.isNew = true
      },
      setInternalUser(employee) {
        this.user.firstName = employee.firstName
        this.user.lastName = employee.lastName
        this.user.email = employee.employeeId
      },
      addInternalUser() {
        this.resetUserData()
        this.showAddEditInternalUserModal = true
      },
      addExternalUser() {
        this.resetUserData()
        this.showAddEditExternalUserModal = true
      },
      editUser(user) {
        this.user = { ...user }
        this.user.roleId = this.user.roleId || ''
        this.user.locationId = this.user.locationId || ''
        this.user.isNew = false
        this.showAddEditInternalUserModal = true
      },
      saveUser() {
        if (this.validateAddOrEditForm()) {
          const self = this
          self.isSavingUser = true
          self.closeMessage()
          const actionType = self.user.isNew ? CREATE_USER : UPDATE_USER
          store.dispatch(actionType, self.user).then(() => {
            self.showSuccessMessage(`User ${actionType == UPDATE_USER ? 'updated' : 'created'} successfully`)
            self.showAddEditInternalUserModal = false
          }).catch((error) => {
            self.handleError(error)
          }).finally(() => {
            self.isSavingUser = false
          })
        }
      },
      tryChangeUserStatus(user) {
        const self = this
        self.selectedUserForStatusChange = { ...user }
        self.statusForSelectedUserForStatusChange = user.status
        self.showUserStatusChangeModal = true
      },
      changeUserStatus() {
        const self = this
        self.isChangingUserStatus = true
        self.closeMessage()
        self.selectedUserForStatusChange.status = self.selectedUserForStatusChange.status ? Enums.Status.Inactive.value : Enums.Status.Active.value
        store.dispatch(UPDATE_USER, self.selectedUserForStatusChange).then(() => {
          self.showSuccessMessage(`user ${self.selectedUserForStatusChange.status ? 'enabled' : 'disabled'} successfully`)
          self.showUserStatusChangeModal = false
        }).catch((error) => {
          self.handleError(error)
          self.selectedUserForStatusChange.status = self.statusForSelectedUserForStatusChange
        }).finally(() => {
          self.isChangingUserStatus = false
        })
      },
      cancelAddOrEdit() {
        this.showAddEditInternalUserModal = false
        this.showAddEditExternalUserModal = false
      },
      cancelUserStatusChange() {
        this.showUserStatusChangeModal = false
      },
      validateAddOrEditForm() {
        this.errors.splice(0, this.errors.length)
        let form = document.querySelector('#userForm')
        form.reportValidity()
        let errors = []
        if (!this.user.email) {
          alert('no user o')
          errors.push({ message: 'No user selected' })
        }
        //if (!this.user.roleId) {
        //  errors.push({ message: 'No role selected' })
        //}
        this.errors = [...errors]
        return !errors.length
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, 'loading users')
      const tasks = []
      tasks.push(store.dispatch(FETCH_USERS, store.state.userModule.currentUser.locationId))
      tasks.push(store.dispatch(FETCH_ROLES))
      tasks.push(store.dispatch(FETCH_LOCATIONS))
      Promise.all(tasks).then(() => {
        next()
      }).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
        })
      }).finally(() => {
        eventBus.$emit(Events.LongOperationCompleted)
      })
    },
    filters: {
      toStatusDescription
    },
    mounted() {
      const self = this
      eventBus.$on(EDIT_USER_EVENT, self.editUser)
      eventBus.$on(CHANGE_USER_STATUS_EVENT, self.tryChangeUserStatus)
      eventBus.$on(CLOSE_MODAL_EVENT, () => {
        self.showAddEditInternalUserModal = false
        self.showAddEditExternalUserModal = false
      })
      eventBus.$on(EMPLOYEE_SEARCH_STARTED_EVENT, (searchString) => {
        self.user.name = ''
        self.user.id = null
        self.searchedEmployees.splice(0, self.searchedEmployees.length)
        employeeService.searchEmployees(searchString)
          .then((result) => {
            if (result.data && result.data.length) {
              self.searchedEmployees = [...result.data]
            }
          })
      })

      eventBus.$on(EMPLOYEE_SELECTED_EVENT, self.setInternalUser)
    },
    beforeDestroy() {
      eventBus.$off(EDIT_USER_EVENT)
      eventBus.$off(CHANGE_USER_STATUS_EVENT)
      eventBus.$off(CLOSE_MODAL_EVENT)
      eventBus.$off(EMPLOYEE_SEARCH_STARTED_EVENT)
      eventBus.$off(EMPLOYEE_SELECTED_EVENT)
    }
  }
</script>
